import { tassign } from 'tassign';

export class TodoReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public addTodo() {
      var newTodo = { id: this.state.todos.length + 1, title: this.action.title };

      return tassign(this.state, {
        todos: this.state.todos.concat(newTodo),
        lastUpdate: new Date()
      });
    }

    public toggleTodo() {
      var todo = this.state.todos.find(t => t.id === this.action.id);

      // Now, we need to find the position of this item in the array.
      var index = this.state.todos.indexOf(todo);

      return tassign(this.state, {
        todos: [
          ...this.state.todos.slice(0, index),
          tassign(todo, { isCompleted: !todo.isCompleted }),
          ...this.state.todos.slice(index + 1),
        ],
        lastUpdate: new Date()
      });
    }

    public removeTodo() {
      return tassign(this.state, {
        todos: this.state.todos.filter(t => t.id !== this.action.id),
        lastUpdate: new Date()
      });
    }

    public clearTodos() {
      return tassign(this.state, {
        todos: [],
        lastUpdate: new Date()
      });
    }
}
