import { tassign } from 'tassign';

export class PrivilageReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public setPrivilages() {
        let session    = this.state.session;
        let privilages = this.action.payload;

        session.user.menu    = privilages.menu;
        session.user.modules = privilages.modules;
        session.user.group   = privilages.group;

        localStorage.setItem("session", JSON.stringify(session));

        return tassign(this.state, { session: session });
    }

    public setPermissions() {
        let session    = this.state.session;
        let permissions = this.action.payload;

        session.user.permissions = permissions;
        localStorage.setItem("session", JSON.stringify(session));

        return tassign(this.state, { session: session });
    }

}
