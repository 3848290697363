import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service'; // our translate service

@Pipe({
  name: 'auxtranslate',
  pure: false
})
export class AuxtranslatePipe implements PipeTransform {

  constructor(private _translate: TranslateService) {}

  transform(value: string, args: any[]): any {
      if (!value) return;
      return this._translate.instant(value);
  }

}
