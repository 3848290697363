// Angular modules
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';

//Template Modules
import { AppRoutingModule } from './app.routing';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { PushNotificationsModule } from 'ng-push';

// **External modules**

// Teradata Covalent Module
import { CovalentModule } from './shared/modules/covalent.module';

// AngularFire Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

// AngularMaterial Modules
import { MaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { FullLayoutComponent, SimpleLayoutComponent } from './template/containers';
import { WelcomeComponent } from './template/views/welcome/welcome.component';

// Redux Modules
import { NgRedux, NgReduxModule, DevToolsExtension } from '@angular-redux/store';
import { IAppState, rootReducer, StoreInitializer } from './shared/modules/redux/store';

// Translation Pipe
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from './shared/pipes/translate/';

// services
import { AuthService } from './shared/services/auth.service';
import { MessageService } from './shared/services/message.service';
import { StageService } from './shared/services/stage.service';
import { GuardService } from './shared/services/guard.service';
import { SocketIOService } from './shared/services/socket-io.service';
import { RealTimeService } from './shared/services/real-time.service';
import { ToasterService } from './shared/services/toaster.service';
import { SoundService } from './shared/services/sound.service';
import { NotifyService } from './shared/services/notify.service';
import { PushNotificationService } from './shared/services/push-notification.service';
import { RequestService } from './shared/services/request.service';
import { FirebaseService } from './shared/services/firebase.service';
import { SessionManager } from './shared/services/session_manager';

// components

// Authentication Components
import { LoginComponent, ActivateComponent, SignupComponent, NotFoundComponent, ErrorPageComponent } from './auth';

// Template components
import { AppAsideComponent, AppBreadcrumbsComponent, AppFooterComponent, AppHeaderComponent, AppSidebarComponent, AppSidebarFooterComponent, AppSidebarFormComponent, AppSidebarHeaderComponent, AppSidebarMinimizerComponent, APP_SIDEBAR_NAV } from './template/components';

// Template Directives
import { AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES } from './template/directives';

// environment
import { environment as ENV } from './../environments/environment';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxBootstrapModule } from './shared/modules/ngx-bootstrap.module';

//firestore
import { FirestoreSettingsToken} from '@angular/fire/firestore';
import { Technical_sheetModule } from './shared/technical_sheets/technical_sheet.module';

// constantes
const APP_CONTAINERS = [
    FullLayoutComponent,
    SimpleLayoutComponent
];

const APP_COMPONENTS = [
    AppAsideComponent,
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV
];

const APP_DIRECTIVES = [
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES
];

const AUTH_COMPONENTS = [
    LoginComponent,
    ActivateComponent,
    NotFoundComponent,
    SignupComponent,
    ErrorPageComponent
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ChartsModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        MaterialModule,
        NgReduxModule,
        FormsModule,
        HttpModule,
        SimpleNotificationsModule.forRoot(),
        PushNotificationsModule,
        CovalentModule,
        AngularFireModule.initializeApp(ENV.firebase.config, ENV.firebase.projectName),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        NgxBootstrapModule,
        Technical_sheetModule
    ],
    declarations: [
        AppComponent,
        TranslatePipe,
        ...APP_CONTAINERS,
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES,
        ...AUTH_COMPONENTS,
        WelcomeComponent
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        { provide: FirestoreSettingsToken, useValue: {} },
        RequestService,
        AuthService,
        SessionManager,
        FirebaseService,
        TRANSLATION_PROVIDERS,
        TranslateService,
        SocketIOService,
        RealTimeService,
        ToasterService,
        SoundService,
        NotifyService,
        PushNotificationService,
        MessageService,
        GuardService,
        StageService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(
        ngRedux: NgRedux<IAppState>,
        auth: AuthService,
        router:Router,
        actRoute:ActivatedRoute
    ){        
        ngRedux.configureStore(rootReducer, StoreInitializer.getInitialState());
        auth.onSignIn().subscribe((session) => {
            if(session){                
                auth.runListeners().subscribe();
            }else{                                            
                if(localStorage.getItem("session")){
                    localStorage.clear();            
                }
                router.navigateByUrl("login");                
            }
        });
    }
}
