import { tassign } from 'tassign';

export class AuthReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public createSession(){
        let session = this.action.payload ? this.action.payload:{};
        localStorage.setItem("session",JSON.stringify(session))
        return tassign(this.state, { session: session });
    }

    public removeSession(){
        let session = {};
        localStorage.removeItem("session");
        return tassign(this.state, { session: session });
    }    

    public setMenu() {
        let session = this.state.session;
        session.menu = this.action.payload;
        return tassign(this.state, { session: session });
    }

    public setPermissions(){
        let session = this.state.session;
        session.permissions = this.action.payload;
        return tassign(this.state, { session: session });
    }

    public setModules(){
        let session = this.state.session;
        session.modules = this.action.payload;
        return tassign(this.state, { session: session });
    }
}
