import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Technical_sheetsComponent } from './components/technical_sheets.component';

const routes: Routes = [
    {
        path: '',
        component: Technical_sheetsComponent,
        data: {
            title: 'Hoja técnica',
            mode: 'catalog'
        }
    }
];

@NgModule({
  imports: [
      RouterModule.forChild(routes)
  ],
  exports: [RouterModule ]
})
export class Technical_sheetRoutingModule { }
