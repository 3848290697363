import { tassign } from 'tassign';
import { Actions } from './actions';
import { TodoReducer }    from './reducers/todo.reducer';
import { CounterReducer } from './reducers/counter.reducer';
import { LoginReducer }   from './reducers/login.reducer';
import { PrivilageReducer } from './reducers/privilage.reducer';
import { SettingsReducer } from './reducers/settings.reducer';
import { NotificationReducer } from './reducers/notification.reducer';
import { StageReducer } from './reducers/stage.reducer';
import { AuthReducer } from './reducers/auth.reducer';

export interface IAppState {
    session:any
}

export class StoreInitializer {
   private static initialState: IAppState = {
        session:null
    }

    static getInitialState(): IAppState {
        let session = JSON.parse(localStorage.getItem("session")) ? JSON.parse(localStorage.getItem("session")):{};
        this.initialState.session = session;
        return this.initialState;
    }
}

export function rootReducer(state: IAppState, action): IAppState {
    var reducer
    switch (action.type) {
        case Actions.CREATE_SESSION:
            reducer = new AuthReducer(state, action);
            return reducer.createSession();
        case Actions.REMOVE_SESSION:
            reducer = new AuthReducer(state, action);
            return reducer.removeSession();
        case Actions.SET_MENU:
            reducer = new AuthReducer(state, action);
            return reducer.setMenu();
        case Actions.SET_PERMISSIONS:
            reducer = new AuthReducer(state, action);
            return reducer.setPermissions();
        case Actions.SET_MODULES:
            reducer = new AuthReducer(state, action);
            return reducer.setModules();

        case Actions.ADD_TODO:
            reducer = new TodoReducer(state, action);
            return reducer.addTodo();
        case Actions.TOGGLE_TODO:
            reducer = new TodoReducer(state, action);
            return reducer.toggleTodo();
        case Actions.REMOVE_TODO:
            reducer = new TodoReducer(state, action);
            return reducer.removeTodo();
        case Actions.CLEAR_TODOS:
            reducer = new TodoReducer(state, action);
            return reducer.clearTodos();

        case Actions.INCREMENT:
            reducer = new CounterReducer(state, action);
            return reducer.increment();
        case Actions.DECREMENT:
            reducer = new CounterReducer(state, action);
            return reducer.decrement();

        case Actions.NEW_SESSION:
            reducer = new LoginReducer(state, action);
            return reducer.setSession();
        case Actions.REMOVE_SESSION:
            reducer = new LoginReducer(state, action);
            return reducer.removeSession();
        case Actions.FAIL_GET_MENU:
            reducer = new LoginReducer(state, action);
            return reducer.removeSession();

        case Actions.SET_PRIVILAGES:
            reducer = new PrivilageReducer(state, action);
            return reducer.setPrivilages();

        case Actions.SET_SETTINGS:
            reducer = new SettingsReducer(state, action);
            return reducer.setSettings();

        case Actions.NEW_NOTIFICATION:
            reducer = new NotificationReducer(state, action);
            return reducer.newNotification();
        case Actions.REMOVE_NOTIFICATION:
            reducer = new NotificationReducer(state, action);
            return reducer.removeNotification();

        case Actions.ADD_STAGE:
            reducer = new StageReducer(state,action);
            return reducer.addStage();
        case Actions.UPDATE_STAGE:
            reducer = new StageReducer(state, action);
            return reducer.updateStage();
        case Actions.REMOVE_STAGE:
            reducer = new StageReducer(state, action);
            return reducer.removeStage();
        case Actions.CLEAR_STAGES:
            reducer = new StageReducer(state, action);
            return reducer.clearStages();            
        case Actions.SET_SCHEMA:
            reducer = new StageReducer(state, action);
            return reducer.setSchema();
        default:
            return state;
  }
}
