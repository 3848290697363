import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class MessageService {

    constructor(){}

    public success(title,text):Promise<any>{
        return swal({
            title: title,
            text: text,
            type: 'success',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        });
    }

    public successMin(title,text):Promise<any>{
        const Toast = swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 6000
          })

          return Toast({
            title: title,
            text:text,
            type: 'success'
            
          })
    }

    public error(title,text):Promise<any>{
        return swal({
            title: title,
            text: text,
            type: 'error',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        })
    }

    public warning(title,text):Promise<any>{
        return swal({
            title: title,
            text: text,
            type: 'warning',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false,
        })
    }

    public info(title,text):Promise<any>{
        return swal({
            title: title,
            text: text,
            type: 'info',
            confirmButtonText: 'Ok',
            allowEscapeKey:false,
            allowOutsideClick:false
        })
    }

    public question(title,text):Promise<any>{
        return new Promise((resolve, reject) => {
            swal({
                title: title,
                text: text,
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#009933',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
            }).then((action)=>{
                if(action.value){
                    resolve(true);
                }else{
                    resolve(false);
                }
            }).catch((err)=>{
                reject(err);
            })
        });
    }

    public cancel(title,text):Promise<any>{
        return new Promise((resolve, reject) => {
            swal({
                title: title,
                text: text,
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#009933',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
            }).then((action)=>{
                if(action.value){
                    resolve(true);
                }else{
                    resolve(false);
                }
            }).catch((err)=>{
                reject(err);
            })
        });
    }

    public custom(config):Promise<any>{
        return swal(config);
    }

    public validate(message){
        swal.showValidationError(message);
    }
}
