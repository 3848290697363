import { tassign } from 'tassign';

export class NotificationReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public newNotification() {
        let session = this.state.session;
        let notification = this.action.payload.notification;
        if(session.notifications === undefined){
            session.notifications = [];
        }
        session.notifications.push(notification);
        localStorage.setItem("session", JSON.stringify(session));
        return tassign(this.state, { session: session });
    }
}
