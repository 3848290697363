import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { RealTimeService } from './../../../shared/services/real-time.service';
import { NotifyService } from './../../../shared/services/notify.service';
import { PushNotificationService } from './../../../shared/services/push-notification.service';
import { StageService } from '../../../shared/services/stage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgRedux, select, IAppState } from '../../../shared/modules/redux';
import { GuardService } from '../../../shared/services/guard.service';
import { AuthService } from '../../../shared/services/auth.service';
import * as _ from "lodash";
import { snapshotChanges } from '@angular/fire/database';
import { trigger, state, style, transition, animate,query,animateChild,stagger,keyframes,group  } from '@angular/animations';

@Component({
    selector: 'app-dashboard',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.style.scss'],
    providers: [],
    animations: [
        trigger('easeInOut', [
          transition(':enter', [
            style({
              opacity: 0
            }),
            animate(".0s ease-in-out", style({
              opacity: 1
            }))
          ]),
          transition(':leave', [
            style({
              opacity: 1
            }),
            animate(".2s ease-in-out", style({
              opacity: 0
            }))
          ])
        ])
      ]
})
export class FullLayoutComponent implements AfterViewInit, OnInit {
    private stages;
    private showTabs = false;
    private showSidebar = true;
    private showMinSidebar = false;
    private windowConfig = { 
        'background': 'white',
        'overflow':'auto',
        'padding-right': '0px',
        'padding-left': '200px',
        'margin-top':'0px',        
        'position': 'absolute',                
        'top': '55px',
        'right': '0px',
        'bottom': '0px'
    };

    public innerWidth: any;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        if(this.innerWidth < 992) {
            this.windowConfig["padding-left"] = "0px";
        }
        if(this.innerWidth >= 992) {
            if( this.showMinSidebar ) {
                this.windowConfig["padding-left"] = "50px";
            } else {
                this.windowConfig["padding-left"] = "200px";
            }
        }
    }

    @select((state) => {
        let tabs = [];
        let stages = state.session.stages;
        if(stages){
            for(let module in stages){                
                tabs.push(stages[module]);
            }
        }
        return tabs?tabs:null;
    }) tabs;

    constructor(
        private realTime:RealTimeService,
        private notify:NotifyService,
        private push:PushNotificationService,
        public route: Router,
        public stage: StageService,
        public guard:GuardService,
        public auth: AuthService,
        public redux:NgRedux<IAppState>
    ){

    }

    toggleTabs(){
        this.showTabs = !this.showTabs; 
        if(this.showTabs == true){
            this.windowConfig.top = "105px";
        }else{
            this.windowConfig.top = "55px";
        }
    }

    toggleSidebar() {
        this.showSidebar = !this.showSidebar;
        if(this.showSidebar == true){
            if(this.showMinSidebar == true)
                this.windowConfig["padding-left"] = "50px";
            else
                this.windowConfig["padding-left"] = "200px";
        }else{
            this.windowConfig["padding-left"] = "0px";
        }
    }

    toggleMinSidebar(event) {
        this.showMinSidebar = event;
        if(this.showMinSidebar == true){
            this.windowConfig["padding-left"] = "50px";
        }else{
            this.windowConfig["padding-left"] = "200px";
        }
    }

    ngOnInit(): void {
        if(this.push.isSupported()){
            if(!this.push.isGranted()){
                this.push.request();
            }
        }
        this.innerWidth = window.innerWidth;
    }

    ngAfterViewInit() {
        this.auth.onSignIn().subscribe((session)=>{
            if(session){
                // valida si existen stages remotos
                this.stage.reloadStages().then((result)=>{
                    if(result){
                        // recargar el último stage activo
                        this.stage.reloadActivatedRoute().then((route)=>{
                            if(route){
                                this.route.navigateByUrl(route);
                            }
                        })
                    }                    
                });
            }
        });
        this.stage.hasStages().subscribe((exist)=>{
            if(exist){
                this.showTabs = true;
                this.windowConfig.top = "105px";
            } else {
                this.showTabs = false;
                this.windowConfig.top = "55px";
            }
        });
    }

    private InitRealTime(){
        this.realTime.connect().then(()=>{
            this.realTime.auth().then((auth) => {
                this.RealTimeListeners();
            }).catch((error)=>{
                console.warn("Comunicación en tiempo real detenida. ",error);
            });
        });
    }

    private RealTimeListeners():void {
        this.realTime.onCruxEvent().subscribe(data => {
            if(this.auth.hasModule(data.module)) {
                if (data.notify !== false) {
                    this.notify.create(data);
                }
            }
        });
        this.realTime.onNotificationEvent().subscribe(data => {
            if (this.auth.hasModule(data.module)) {
                this.notify.create(data);
            }
        });
    }

    private removeTab(stage){        
        this.stage.removeStage(stage);
    }
 }
