import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { MessageService } from './message.service';
import { SessionManager } from './session_manager';
import { StageService } from './stage.service';
import { AuthService } from './auth.service';
import * as _ from "lodash";
import { NgRedux, select, IAppState } from './../modules/redux';

@Injectable()
export class GuardService implements CanActivate {
    @select(state => state.modules ? state.modules : null) modules;
    @select(state => state.permissions ? state.permissions : null) permissions;

    constructor(
        private ngRedux: NgRedux<IAppState>,
        private message : MessageService,
        private router : Router,
        private session :SessionManager,
        private stage : StageService,
        private auth : AuthService
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try{
            return await this.validateUrl(state, route);
        }catch(error){
            return false;
        }
    }

    public validateUrl(state,route):Promise<any>{
        let isSignedIn = this.auth.isSignedIn();
        return new Promise((resolve, reject)=>{
            switch (state.url) {
                case '/login':
                    if (isSignedIn) {                        
                        this.router.navigateByUrl('welcome');
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                    break;

                case '/welcome':
                    if (isSignedIn) {
                        resolve(true);
                    } else {
                        this.router.navigateByUrl('login');
                        resolve(false);
                    }
                    break;

                default:
                    if (isSignedIn) {                        
                        if(this.auth.hasModule(route.data.module)){
                            this.stage.addStage(route.data.module,state.url,route.data.label);                            
                            resolve(true);
                        }else{
                            this.router.navigateByUrl('welcome');
                            resolve(false);
                        }
                    } else {
                        this.router.navigateByUrl('login');
                        resolve(false);
                    }
                    break;
            }
        });
    }
}
