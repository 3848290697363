import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

enableProdMode(); //La optimización de angular siempre esta activa

platformBrowserDynamic().bootstrapModule(AppModule);
