import { Component, OnInit } from '@angular/core';
import { Http, Response, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { AuthService } from './../../../shared/services/auth.service';
import { FirebaseService } from './../../../shared/services/firebase.service';
import { RequestService } from './../../../shared/services/request.service';
@Component({
    templateUrl: 'welcome.component.html',
    providers: [AuthService, FirebaseService]
})
export class WelcomeComponent implements OnInit {
    constructor(
        private http: Http,
        private auth: AuthService,
        private firebase: FirebaseService,
        private request: RequestService
    ) {
        auth.onSignIn().subscribe((user)=>{
            if(user){
                auth.getToken().then((token) => {
                })
            }
        })
    }
    ngOnInit(): void {

    }
}
