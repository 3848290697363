import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MessageService } from './message.service';
import * as _ from 'lodash';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage'

@Injectable()
export class FirebaseService {
    constructor(
        private message: MessageService,
        private db: AngularFirestore,
        private storage:AngularFireStorage
    ) {

    }

    public once(collection):Promise<any> {
        let items = [];
        return new Promise((resolve,reject)=>{
            this.db.collection(collection).ref.get().then((array)=>{
                array.forEach((item)=>{
                    items.push(item.data());
                });
                resolve(items);
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    public uploadImage(path, imageFile): Promise<any> {
        return new Promise((resolve, reject) => {
            const ref = this.storage.ref(path);
            const task = ref.put(imageFile).then((res)=>{
                resolve(res);
            }).catch((error)=>{
                reject(error);
            })
        });
    }

    public deleteImage(path): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storage.ref(path).delete().toPromise().then(result => {
                resolve(result);
            }).catch(error => {
                reject(error);
            });
        });
    }

    public onceWhere(collection, attributeName, condition, attributeValue): Promise<any> {
        let items = [];
        return new Promise((resolve, reject) => {
            this.db.collection(collection).ref.where(attributeName, condition, attributeValue).get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    items.push(doc.data());
                });
                resolve(items);
            });
        });
    }

    getDocumentOnce(collection,document){
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(document)
            .ref.get().then(snapshot => {
                resolve(snapshot.data())
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    public getCollectionByAttributeValue(collection, attributeName, attributeValue): Promise<any> {
        let items = [];
        return new Promise((resolve, reject) => {
            this.db.collection(collection).ref.where(attributeName, '==', attributeValue).get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    items.push(doc.data());
                });
                resolve(items);
            }).catch(error => {
                reject(error);
            });;
        });
    }

    public getCollectionByMultipleValues(collection, options: any[]): Promise<any> {
        let items = [];
        let collectionRef = this.db.collection(collection).ref;
        return new Promise((resolve, reject) => {
            let query;
            for (let i = 0; i < options.length; i++) {
                let option = options[i];
                if(i == 0){
                    query = collectionRef.where(option.attributeName, option.operator || '==', option.value);
                }else {
                    query = query.where(option.attributeName, option.operator || '==', option.value);
                }
            }
            query.get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    items.push(doc.data());
                });
                resolve(items);
            }).catch(error => {
                reject(error);
            });
        });
    }

    uploadFile(event,fileName?:string):Promise<any>{
        return new Promise((resolve,reject)=>{
            const file = event.target.files[0];
            let name = fileName?fileName:file.name;
            const filePath = `/enterprisesTemp/${name}`;
            const ref = this.storage.ref(filePath);
            ref.put(file).then((res) => {
                resolve(res);
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    public getFileUrl(path): Promise<any> {
        return new Promise((resolve, reject) => {
            let ref = this.storage.ref(path);
            ref.getDownloadURL().toPromise().then(url => {
                resolve(url);
            }).catch(error => {
                console.log(error);
                reject(error);
            })
        }).catch(error => {
            
        });
    }
}
