import { Component } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from './../../../shared/modules/redux/store';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html'
})
export class AppAsideComponent{
    @select(state => state.session? state.session.notifications:null) notifications;
    constructor(private ngRedux:NgRedux<IAppState>) {}
}
