import { tassign } from 'tassign';

export class LoginReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public setSession() {
        let session = this.action.payload.session;
        localStorage.setItem("session", JSON.stringify(session));

        return tassign(this.state, { session: session });
    }

    public newSessionToken() {
        let session = this.state.session;
        let token   = this.action.payload.token;

        session.token = token;
        localStorage.setItem("session", JSON.stringify(session));

        return tassign(this.state, { session: session });
    }

    public removeSession() {
        localStorage.removeItem("session");

        return tassign(this.state, { session: null });
    }

}
