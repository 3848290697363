export enum Actions {
    CREATE_SESSION,
    REMOVE_SESSION,
    SET_MENU,
    SET_PERMISSIONS,
    SET_MODULES,

    ADD_TODO,
    TOGGLE_TODO,
    REMOVE_TODO,
    CLEAR_TODOS,

    INCREMENT,
    DECREMENT,

    NEW_SESSION,
    NEW_SESSION_TOKEN,

    SET_PRIVILAGES,
    SET_SETTINGS,

    FAIL_GET_MENU,

    NEW_NOTIFICATION,
    REMOVE_NOTIFICATION,

    ADD_STAGE,
    UPDATE_STAGE,
    REMOVE_STAGE,
    CLEAR_STAGES,
    SET_SCHEMA
};
