import { Injectable } from '@angular/core';
import { Session } from '../../shared/models/session';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../modules/redux/store';

@Injectable()
export class SessionManager {

    constructor(
      private ngRedux: NgRedux<IAppState>
    ){}


    public getSession() : Session {
        const { session }= this.ngRedux.getState();
        return session;
    }

    public existsSession() : boolean {
        const { session } = this.ngRedux.getState();
        return session !== null;
    }

    public getSettings(): any {
        const { session } = this.ngRedux.getState();
        return session.settings;
    }

    public getModules(){
        const { session } = this.ngRedux.getState();
        return session.user.modules;
    }

    public getPermissions(){
        const { session } = this.ngRedux.getState();
        return session.user.permissions.permissions;
    }
}
