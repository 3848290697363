import { User } from '../../shared/models/user';
export class Session {
    public user:User;
    public status:number;
    public token:string;

    constructor(user, status, token){
        this.user = user;
        this.status = status;
        this.token = token;
    }
}
