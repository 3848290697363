import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionManager }  from './../../shared/services/session_manager';
import { Session } from '../../shared/models/session';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';

@Injectable()
export class SocketIOService {
    private CONFIG:any = environment;
    private socket:SocketIOClient.Socket;
    private session:Session;
    private IOAuth:any;

    constructor(private sessionManager: SessionManager) {
    }

    connect():Promise<any>{
        return new Promise((resolve, reject) => {
            this.socket = null;
            this.socket = io(this.CONFIG.socketIO);
            this.socket.on('connect',() => {
                resolve(this.socket);
            });
        });
    }

    disconnect():void {
        this.socket.removeAllListeners();
    }

    emitAuth():Promise<any>{
        return new Promise((resolve, reject) => {
            if(this.sessionManager.existsSession){
                this.session = this.sessionManager.getSession();
                this.IOAuth = { auth :{
                    "username":this.session.user.username,
                    "token":this.session.token,
                    "app":this.CONFIG.app,
                    "name":this.session.user['nombre'] + ' ' + this.session.user['apellidos']
                }};
                this.socket.emit('svrLogin', this.IOAuth, (data) => {
                    resolve(data);
                });
            }else{
                reject("No existe una sesión de usuario activa");
            }
        });
    }

    emitLogOut(){
        if(this.sessionManager.existsSession){
            this. session = this.sessionManager.getSession();
            this.IOAuth = { auth :{
                "username":this.session.user.username,
                "token":this.session.token,
                "app":this.CONFIG.app,
                "name":this.session.user['nombre'] + ' ' + this.session.user['apellidos']
            }};
        }
        this.socket.emit('svrLogout',this.IOAuth,(data) => {
            this.socket.disconnect();
        });
    }

    onEvent(event){
        let observable = new Observable(observer => {
            this.socket.on(event, (data) => {
                observer.next(data);
            });
            return () => {
                this.socket.disconnect();
            };
        })
        return observable;
    }

    emitEvent(data,event):Promise<any>{
        return new Promise((resolve,reject)=>{
            if(this.sessionManager.existsSession){
                this. session = this.sessionManager.getSession();
                this.IOAuth = { auth :{
                    "username":this.session.user.username,
                    "token":this.session.token,
                    "app":this.CONFIG.app,
                    "name":this.session.user['nombre'] + ' ' + this.session.user['apellidos']
                }};
            }
            data['auth'] =  this.IOAuth.auth;
            this.socket.emit(event,data,(payload)=>{
                resolve(payload);
            });
        });
    }
}
