import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, snapshotChanges, DatabaseSnapshot } from '@angular/fire/database';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../modules/redux/store';
import { Actions } from '../modules/redux/actions';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as _ from "lodash";
import { Observable } from 'rxjs';

@Injectable()
export class StageService {
    private observerStages;
    private observerSchema;

    constructor(
        private ngRedux: NgRedux<IAppState>,
        private ActivatedRoute: ActivatedRoute,
        private router: Router,
        private storage:AngularFireDatabase,
        private auth:AuthService
    ){}

    public reloadActivatedRoute():Promise<any> {
        return new Promise((resolve,reject) => {
            let strRef = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/ActivatedRoute`;
            let temp = this.storage.object(strRef).valueChanges().subscribe((Route:string) => {
                temp.unsubscribe();
                resolve(Route);
            },(error)=>{
                temp.unsubscribe();
                reject(error);
            });
        });        
    }

    public reloadStages():Promise<any> { //recupera los stages del usuario abiertos con anterioridad que no fueron cerrados
        return new Promise((resolve,reject)=>{
            let { session } = this.ngRedux.getState();
            let strRef = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages`;
            // 1 - consulta los stages remotos
            this.observerStages = this.storage.object(strRef).valueChanges().subscribe((stages:any) => {            
                if(stages){
                    // 2 - valida si los stages locales siguen vigentes en el storage en tiempo real (firebase)
                    for(let localStage in session.stages){ 
                        let local_stage = session.stages[localStage];
                        if(!stages[local_stage.module])
                            this.removeStage(local_stage)
                    }

                    // 3 - Agrega los stages remotos al arreglo de stages local (redux)
                    for(let module in stages) {
                        let stage = stages[module];
                        let payload:any = {
                            module:stage.module,
                            path:stage.path,
                            label:stage.label                            
                        };

                        //valida si tiene schema
                        if(stage.schema) { 
                            payload.schema = stage.schema;
                        }

                        this.ngRedux.dispatch({ type: Actions.ADD_STAGE, payload: payload });                        
                    }
                    resolve(true);
                }else{
                    //ocurre cuando se elimina el último stage remoto
                    this.ngRedux.dispatch({ type: Actions.CLEAR_STAGES });
                    this.storage.database.ref(`states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/ActivatedRoute`).remove();
                    this.router.navigateByUrl('welcome');
                    resolve(false);
                }
            });
        })
    }

    stopListeners(){
        this.observerStages.unsubscribe();
        this.observerSchema.off();
    }   

    public setActiveStage(path) {
        let strRef = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/ActivatedRoute`;
        let ref = this.storage.database.ref(strRef);        
        ref.set(path);
    }

    public addStage(module,path,label) {
        path = "/" + path.split("/")[1];
        let { session } = this.ngRedux.getState();
        let stage:any = { module:module, path:path, label:label };        
        this.existStage(stage.module).then((res)=>{
            if(res){
                this.setActiveStage(path);
            }else{
                this.ngRedux.dispatch({ type: Actions.ADD_STAGE, payload: stage });
                let str = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages/${module}`;
                let ref = this.storage.database.ref(str);
                ref.set(stage).then(()=>{
                    this.setActiveStage(path);
                });
            }
        });
    }

    public setSchema(schema:any,module?:string){
        //schema = this.sanitizeSchema(schema);
        let stage:any;
        if(!module) stage = this.ActivatedRoute.snapshot.data;
        else stage.module = module;
        this.existStage(stage.module).then((res)=>{
            if(res){
                this.ngRedux.dispatch({ type: Actions.SET_SCHEMA, payload: { module: stage.module, schema: schema } });
                let str = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages/${stage.module}/schema`;
                let ref = this.storage.database.ref(str);
                ref.set(JSON.parse(JSON.stringify(schema)))
            }
        });
    }

    sanitizeSchema(schema){
        let hasChildren = (element) => {
            if(Object.getOwnPropertyNames(schema).length > 0){
                return true;
            }else{
                return false;
            }
        }        

        let valObject = (data)=>{
            if(hasChildren(data)) {
                for(let key  of Object.getOwnPropertyNames(schema)) {
                    if(schema[key] instanceof Object) {
                        valObject(schema[key]);
                    }
                    if(schema[key] instanceof Array) {
                        valArray(schema[key]);
                    }
                    if(schema[key] instanceof String){
                        valString(schema[key]);
                    }
                    if(schema[key] instanceof Number){
                        valNumber(schema[key]);
                    }                    
                }
            } else {
                data = "empty_object";
            }
        }

        let valArray = (data) => {
            for(let item in data) {
                if(data[item] instanceof Object) {
                    valObject(data[item]);
                }
                if(data[item] instanceof Array){
                    valArray(data[item]);
                }
                if(data[item] instanceof String){
                    valString(data[item]);
                }
                if(data[item] instanceof Number){
                    valNumber(data[item]);
                }
            }
        }

        let valString = (data) => {
            if(data == "")
                data = "empty_string";                    
        }

        let valNumber = (data) => {
            if(data === undefined || data === null)
                data = "empty_number";         
        }

        if(schema){
            if(schema instanceof Object) {
                valObject(schema);
            }
            if(schema instanceof Array) {
                valArray(schema);
            }
            if(schema instanceof String) {
                valString(schema);
            }
            if(schema instanceof Number) {
                valNumber(schema);
            }            
        }

        return schema;
    }
    
    public getSchema(module?:string):Promise<any>{
        let stage:any;        
        if(!module) stage = this.ActivatedRoute.snapshot.data;
        else stage.module = module;            

        return new Promise((resolve,reject)=>{
            let str = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages/${stage.module}/schema`;
            let ref = this.storage.database.ref(str);
            ref.once('value',(snap) => {
                resolve(snap.val());
            },(error)=>{
                reject(error);
            });
        });
    }

    public onChangeSchema(module?:string):Observable<any>{
        let stage:any;        
        if(!module) stage = this.ActivatedRoute.snapshot.data;
        else stage.module = module;            

        return new Observable((observer) => {
            let strRef = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages/${stage.module}/schema`;
            this.observerSchema = this.storage.database.ref(strRef).on('value',(snap)=>{
                observer.next(snap.val());
            },(error)=>{
                observer.error(error);
            });            
        });
    }

    public removeStage(stage) {
        let module = stage.module;        
        let { session } = this.ngRedux.getState();
        let idxStage = _.indexOf(Object.keys(session.stages),module)

        this.ngRedux.dispatch( { type: Actions.REMOVE_STAGE, payload: { module: module } } );
        this.storage.database.ref(`states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages/${module}`).remove(); 
                
        if (Object.keys(session.stages).length === 0) {            
            this.router.navigateByUrl('welcome');
        } else {
            let stageToRemove = this.router.url.split('/')[1];
            let currentStage = stage.path.split('/')[1];

            //valida si el número de stages existentes es mayor a el valor del index del stage que se eliminó, 
            //para saber si se eliminó el stage de la última posición
            if ( Object.keys(session.stages).length <= idxStage ) {
                idxStage = idxStage - 1; // si era el stage con la última posición toma el siguiente a la izquierda, en caso contrarió tome el siguiente a la derecha
            }

            if ( stageToRemove == currentStage ) { //si el stage que quiero eliminar es el mismo en el que me encuentro
                this.router.navigateByUrl( session.stages[ Object.keys(session.stages)[idxStage] ].path );
                this.storage.database.ref(`states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/ActivatedRoute`).remove();
            }
        }
    }

    public existStage(module:string):Promise<any>{
        return new Promise((resolve,reject)=>{
            let str = `states/${this.auth.getEnterpriseId()}/${this.auth.getUserId()}/stages/${module}`;
            let ref = this.storage.database.ref(str);
            ref.once('value',(snap) => {
                resolve(snap.val());
            },(error)=>{
                reject(error);
            });
        });
    }

    public hasStages():Observable<any>{
        return new Observable((observer)=>{            
            this.ngRedux.subscribe(()=>{
                let { session } = this.ngRedux.getState();
                if( session.stages ) {
                    let nItems = 0;                
                    for(let stage in session.stages){
                        nItems++;
                    }
                    if(nItems > 0){
                        observer.next(true);
                    } else {
                        observer.next(false);
                    }
                }
            });
        });        
    }
}