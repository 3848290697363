import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar-minimizer',
  templateUrl: './app-sidebar-minimizer.component.html'
})
export class AppSidebarMinimizerComponent {
  private status:boolean = false;
  @Output() onMinimize : EventEmitter<any> = new EventEmitter();

  minimize() {
    this.status = !this.status;
    this.onMinimize.emit(this.status);
  }
}
