export const LANG_ES_NAME = 'es';
export const LANG_ES_TRANS = {
    '#ENTERPRISES': 'Empresas',
    '#PRODUCTS': 'Productos',
    '#WORKORDER': 'Orden de trabajo',
    '#MATERIAL_LIST': 'Lista de materiales',
    '#PRODUCTION': 'Producción',
    '#CNC': 'CNC',
    '#TECHNICAL_SHEET':'Hoja Técnica',
    '#TECHNICAL_SHEETS_GRAL':'Hoja Técnica',
    '#REPORTS': 'Reportes',
    '#CNC-ADMIN': 'CNC-ADMIN',
    '#PRODUCTS-LINES-CAT': 'Líneas de productos',
    '#QUOTATIONS':'Cotizaciones',
    '#QUOTATION' :'General',
    '#ORDERS':'Pedidos',
    '#PROFILETYPES':'Tipo de perfiles',
    '#PROFILES':'Perfiles',
    '#WAREHOUSES':'Almacenes',
    '#MOVEMENTSTYPES': 'Tipo Movimientos',
    '#CUSTOMERS': 'Clientes',
    '#CATEGORIES':'Categorías',
    '#MATERIALS':'Materiales',
    '#PRIVILEGES': 'Privilegios',
    '#SETTINGS':'Configuraciones',
    '#ECOMMERCE':'Tienda en línea',
    '#SCHEMA-PROPERTIES':'Esquemas',
    '#CONFIGURATIONS': 'Config de módulos',
    '#USERSGROUP': 'Grupo de usuarios',
    '#CUTTINGPROGRAMS': 'Programas de corte',
    '#CUTTINGMONITOR': 'Monitor de corte',
    '#CATALOGS': 'Catálogos',
    '#MACHINES': 'Máquinas de corte',
    '#OPERATORS': 'Operadores',
    '#DELIVERIES': 'Embarque',
    '#DISCOUNTS': 'Descuentos',
    '#REQUEST':'Solicitud',
    '#COMMISSIONS':'Comisiones',
    '#MODULEGROUPS': 'Grupo de módulos',
    '#CALCULATE':'Cálculo',
    '#SALESAGENTS':'Ingenieros de Venta',
    '#RULES': 'Reglas',
    '#QUOTATIONSREPORTS': 'Reportes',
    '#Cutting Programs': 'Programas de Corte',
    '#Program': 'Programa',
    '#Orders': 'Pedidos',
    '#Folio': 'Folio',
    '#Client': 'Cliente',
    '#Branch Office': 'Sucursal',
    '#Cuttings': 'Partidas',
    '#Cutting order': 'Partida pedido',
    '#Status': 'Estatus',
    '#Length': 'Longitud',
    '#Product key': 'Clave Producto',
    '#Layer label': 'Etiqueta Capa',
    '#Created at': 'Creado el',
    '#AREAS': 'Áreas',
    '#MODULES': 'Módulos',
    '#MODULECONFIGS': 'Configuraciones',
    '#PLANSCAT': 'Planes',
    '#PLANS': 'Planes',
    '#USERS': 'Usuarios',
    '#MT': 'm',
    '#ADMIN': 'Administrador',
    '#MODULESCAT': 'Módulos',
    '#Item': 'Partida',
    //
    '#Initialized': 'Inicializado',
    '#Finished': 'Finalizado',
    '#Partial_transfered': 'Transferido parcial',
    '#Created': 'Creado',
    '#Pending': 'Pendiente',
    '#Processing': 'Procesando',
    '#Cut': 'Cortado',
    '#Packaged': 'Empacado',
    '#Sent': 'Enviado',
    '#Delivery_Created': 'Envio creado',
    '#Delivered': 'Entregado',
    '#Requested': 'Solicitado',
    '#Found': 'Encontrado',
    '#Canceled': 'Cancelado',
    '#All' : 'Todos'
};
