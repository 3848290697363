import { Component } from '@angular/core';
// import { SessionManager } from './../../shared/services/session_manager';
@Component({
  selector: 'app-sidebar-header',
  templateUrl: './app-sidebar-header.component.html'
})
export class AppSidebarHeaderComponent {
    public session:any;
    constructor(){

    }
 }
