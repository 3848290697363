import { Injectable } from '@angular/core';

import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class ToasterService {
    constructor(
        private toast: NotificationsService
    ) {}

    create(type,title,content,config,data){
        switch(type){
            case 'info':
                let info = this.toast.info(title,content,config);
                info.click.subscribe(()=>{
                    // console.log(data);
                });
            break;

            case 'success':
                let success = this.toast.success(title,content,config);
                success.click.subscribe(()=>{
                    // console.log(data);
                });
            break;

            case 'warning':
                let warning = this.toast.warn(title,content,config);
                warning.click.subscribe(()=>{
                    // console.log(data);
                });
            break;

            case 'error':
                let error = this.toast.error(title,content,config);
                error.click.subscribe(()=>{
                    // console.log(data);
                });
            break;
        }
    }
}
