import { Injectable } from '@angular/core';
import { FirebaseService } from 'app/shared/services/firebase.service';

@Injectable({
    providedIn: 'root'
})

export class technical_sheetService {
    
    constructor(
        private firebase: FirebaseService
    ) { }

   
    getDocs(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.firebase.getDocumentOnce('settings','pathassets').then(doc => {
                resolve(doc);
            }).catch(error => {
                reject(error);
            });
        });
    }
}
