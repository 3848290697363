import 'rxjs/add/operator/pairwise';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, ActivateComponent, NotFoundComponent, ErrorPageComponent, SignupComponent } from './auth';
import { FullLayoutComponent } from './template/containers';
import { WelcomeComponent } from './template/views/welcome/welcome.component';
import { GuardService } from './shared/services/guard.service';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'activate',
        component: ActivateComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [GuardService]
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'error-page',
        component: ErrorPageComponent
    },
    {
        path: '',
        component: FullLayoutComponent,
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'welcome',
                component: WelcomeComponent,
                canActivate: [GuardService]
            },
            {
                path: 'dashboard',
                loadChildren: './template/views/dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'enterprises',
                loadChildren: './admin/enterprises/enterprises.module#EnterprisesModule',
                data:{ module:'enterprisescat', label: 'Empresas' },
                canActivate: [GuardService]
            },
            {
                path: 'users',
                loadChildren: './admin/users/users.module#UsersModule',
                data:{ module:'userscat', label: 'Usuarios' },
                canActivate: [GuardService]
            },
            {
                path: 'module-configs',
                loadChildren: './admin/module-configs/module-configs.module#ModuleConfigsModule',
                data:{module:'module-configs',label:'Config. de módulos'},
                canActivate: [GuardService]
            },
            {
                path: 'modules',
                loadChildren: './admin/modules/modules.module#ModulesModule',
                data:{module:'modulescat',label:'Módulos'},
                canActivate: [GuardService]
            },
            {
                path: 'module-groups',
                loadChildren: './admin/modules-group/module-groups.module#ModuleGroupsModule',
                data:{module:'modulegroupscat',label:'Grupos de módulos'},
                canActivate: [GuardService]
            },
            {
                path: 'plans',
                loadChildren: './admin/plans/plans.module#PlansModule',
                data: { module: 'planscat', label: 'Planes' },
                canActivate: [GuardService]
            },
            {
                path: 'privileges',
                loadChildren: './admin/privileges/privileges.module#PrivilegesModule',
                data:{module:'privileges',label:'Privilegios'},
                canActivate: [GuardService]
            },
            {
                path: 'users-group',
                loadChildren: './admin/users-group/users-group.module#UsersGroupModule',
                data:{module:'users-group',label:'Grupo de usuarios'},
                canActivate: [GuardService]
            },
            {
                path: 'profiletypes',
                loadChildren: './cnc/catalogs/profiletypes/profiletypes.module#ProfiletypesModule',
                data:{module:'profiletypes',label:'Tipo de perfiles'},
                canActivate: [GuardService]
            },
            {
                path: 'profiles',
                loadChildren: './cnc/catalogs/profiles/profiles.module#ProfilesModule',
                data:{module:'profiles',label:'Perfiles'},
                canActivate: [GuardService]
            },
            {
                path: 'materials',
                loadChildren: './cnc/materials/materials.module#MaterialsModule',
                data:{ module:'materials', label: 'Materiales' },
                canActivate: [GuardService]
            },
            {
                path: 'material_list',
                loadChildren: './cnc/material_list/materialsList.module#MaterialsListModule',
                data:{ module:'material_list', label: 'Lista de materiales' },
                canActivate: [GuardService]
            },
            {
                path: 'products',
                loadChildren: './cnc/products/products.module#ProductsModule',
                data:{ module:'products', label: 'Productos' },
                canActivate: [GuardService]
            },
            {
                path: 'products-lines-cat',
                loadChildren: './cnc/products-line-cat/products-lines-cat.module#ProductsLinesCatModule',
                data:{ module:'products-lines-cat', label: 'Líneas de productos' },
                canActivate: [GuardService]
            },
            {
                path: 'warehouses',
                loadChildren: './cnc/catalogs/warehouses/warehouses.module#WarehousesModule',
                data:{module:'warehouses',label:'Almacén'},
                canActivate: [GuardService]
            },
            {
                path: 'movementstypes',
                loadChildren: './cnc/catalogs/movementstypes/movementstypes.module#MovementstypesModule',
                data:{module:'movementstypes',label:'Tipos de Movimientos'},
                canActivate: [GuardService]
            },
            {
                path: 'customers',
                loadChildren: './cnc/catalogs/customers/customers.module#CustomersModule',
                data:{module:'customers',label:'Clientes'},
                canActivate: [GuardService]
            },
            {
                path: 'quotations',
                loadChildren: './cnc/quotations/quotations.module#QuotationsModule',
                data:{module:'quotations',label:'Cotizaciones'},
                canActivate: [GuardService]
            },
            {
                path: 'orders',
                loadChildren: './cnc/orders/orders.module#OrdersModule',
                data:{module:'orders',label:'Pedidos'},
                canActivate: [GuardService]
            },
            {
                path: 'production',
                loadChildren: './cnc/workorder/workorders.module#workOrdersModule',
                data:{module:'workorder',label:'Orden de trabajo'},
                canActivate: [GuardService]
            },
            {
                path: 'technical_sheet',
                loadChildren: './cnc/technical_sheet_cnc-admin/technical_sheet_cnc-admin.module#TechnicalSheetCncAdminModule',
                data:{module:'technical_sheet',label:'Hojá técnica'},
                canActivate: [GuardService]
            },
            {
                path: 'technical_sheets_gral',
                loadChildren: './cnc/catalogs/technical_sheet_cnc-quote/technical_sheet_cnc-quote.module#TechnicalSheetCncQuoteModule',
                data:{module:'technical_sheets_gral',label:'Hojá técnica'},
                canActivate: [GuardService]
            },
            {
                path: 'reports',
                loadChildren: './cnc/reports/reports.module#ReportsModule',
                data:{module:'reports',label:'Reportes'},
                canActivate: [GuardService]
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'welcome'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
