import { Component, OnInit, Input } from '@angular/core';
import { technical_sheetService } from '../services/technical_sheet.service';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'technicalSheets',
    templateUrl: '../templates/technical_sheets.component.html',
    styleUrls: ['../styles/technical_sheets.scss'],
    providers: []
})
export class Technical_sheetsComponent implements OnInit {
    @Input() public schema: any;
    @Input() public loadingService: any;

    public urlTechnicalSheet;
    public url;
    constructor(private domSanitizer: DomSanitizer, private service: technical_sheetService
    ) { }

    ngOnInit() {
        this.getUrl();
    }

    getUrl() {
        let doc;
        this.service.getDocs().then((url) => {
            doc = url.TECHNICAL_SHEET;
            this.urlTechnicalSheet = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/gview?url=${doc}&embedded=true`);
        }).catch(error => {
            console.error(error);
        })
    }
}
