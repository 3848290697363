import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, ResponseContentType, URLSearchParams } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpConst } from '../constants/httpConst';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/timeout';
import { error } from 'selenium-webdriver';
import { Request } from '@angular/http/src/static_request';
import { Session } from '../../shared/models/session';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../modules/redux/store';
import { Actions } from '../modules/redux/actions';
import { MessageService } from './message.service';
import { AuthService } from './auth.service';
import { StageService } from './stage.service';

@Injectable()
export class RequestService {
    private api:any = environment.api;
    private readonly newApi: string = environment.newApi;

    constructor(
        private http : Http,
        private ngRedux : NgRedux<IAppState>,
        private message : MessageService,
        private auth : AuthService,
        private route : Router,
        private stage : StageService
    ){}

    getPrinter(url,params,sysmodule,timeout, useNewAPI:boolean = false):Promise<any>{
      return new Promise((resolve,reject)=>{
        this.getQueryOptions2(sysmodule, params)
        .then(options => {
          this.http.get(`${useNewAPI ? this.newApi : this.api}${url}`, options)
           .subscribe( data => {
              const file = new Blob([data.blob()], {type: 'application/pdf'});
              const fileURL = window.URL.createObjectURL(file);
              resolve({
                message: 'Reporte generado correctamente',
                filename: 'report.pdf',
                url: fileURL,
              });
           }); 
        })
      });
    }


    get(url,params,sysmodule,timeout,useNewAPI:boolean = false):Promise<any>{
      return new Promise((resolve,reject)=>{
        this.getQueryOptions(sysmodule, params)
        .then(options => {
            this.http.get(`${useNewAPI ? this.newApi : this.api}${url}`, options)
            .timeout(timeout)
            .map((res:Response)=>{return res})
            .subscribe((data)  => {
              this.updateSession(data);
              resolve(data.json());
            },(error:Response) => {
              this.validateRequest(error);
              reject(error);
            });
        })
      });
    }

    post(url,params,sysmodule,timeout,useNewAPI:boolean = false):Promise<any>{
        return new Promise((resolve,reject)=>{
            this.getOptions(sysmodule)
            .then(options => {
                this.http.post(this.api+url,params,options)
                .timeout(timeout)
                .map((res:Response)=>{return res})
                .subscribe((data)  => {
                    this.updateSession(data);
                    resolve(data.json());
                },(error:Response) => {
                    this.validateRequest(error);
                    reject(error);
                });
            })
        });
    }

    put(url,params,sysmodule,timeout,useNewAPI:boolean = false):Promise<any>{
        return new Promise((resolve,reject)=>{
            this.getOptions(sysmodule)
            .then(options => {
                this.http.put(this.api+url,params,options)
                .timeout(timeout)
                .map((res:Response)=>{return res})
                .subscribe((data)  => {
                    this.updateSession(data);
                    resolve(data.json());
                },(error:Response) => {
                    this.validateRequest(error);
                    reject(error);
                });
            })
        });
    }

    delete(url, params, sysmodule, timeout, useNewAPI:boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getQueryOptions(sysmodule, params)
            .then(options => {
                this.http.delete(this.api + url, options)
                .timeout(timeout)
                .map((res: Response) => { return res })
                .subscribe((data) => {
                    this.updateSession(data);
                    resolve(data.json());
                }, (error: Response) => {
                    this.validateRequest(error);
                    reject(error);
                });
            })
        });
    }

    getOptions(sysModule):Promise<RequestOptions>{
        return new Promise((resolve, reject) => {
            let systemConfig:any = environment;
            let constHeaders:any = HttpConst.headers;
            let headers = constHeaders;

            this.auth.getToken().then((token) => {
                headers.token = token;
                headers.Authorization = `Bearer ${token}`;
                headers.app = systemConfig.app;
                headers.module = sysModule;
                headers.uid = this.auth.getUserId();
                headers['Content-Type']= 'application/json';

                resolve(new RequestOptions({ headers: headers}));
            })
        })
    }

    getQueryOptions(sysModule,params):Promise<RequestOptions>{
        return new Promise((resolve, reject) => {
            let systemConfig:any = environment;
            let urlSearch = new URLSearchParams();
            let constHeaders:any = HttpConst.headers;
            let headers = constHeaders;

            for(let key in params) {
              urlSearch.append(key, params[key]);
            }

            this.auth.getToken().then((token) => {
                headers.token = token;
                headers.Authorization = `Bearer ${token}`;
                headers.app = systemConfig.app;
                headers.module = sysModule;
                headers.uid = this.auth.getUserId();
                headers['Content-Type']= 'application/json';

                resolve(new RequestOptions({ headers: headers, params: urlSearch }));
            })
        })
    }
    getQueryOptions2(sysModule,params):Promise<RequestOptions>{
      return new Promise((resolve, reject) => {
          let systemConfig:any = environment;
          let urlSearch = new URLSearchParams();
          let constHeaders:any = HttpConst.headers;
          let headers = constHeaders;

          for(let key in params) {
            urlSearch.append(key, params[key]);
          }

          this.auth.getToken().then((token) => {
              headers.token = token;
              headers.Authorization = `Bearer ${token}`;
              headers.app = systemConfig.app;
              headers.module = sysModule;
              headers.uid = this.auth.getUserId();
              headers['Content-Type']= 'application/pdf';

              resolve(new RequestOptions({ headers: headers, params: urlSearch, responseType: ResponseContentType.ArrayBuffer }));
          })
      })
  }


    updateSession(res){
        let { session } = this.ngRedux.getState();
        let newSession = new Session(
            session.user,
            res.status,
            res.headers.get('token')
        );
        Object.assign(session, newSession);
        this.ngRedux.dispatch({ type: Actions.NEW_SESSION, payload: { session: session} });
    }

    validateRequest(error){
        switch(error.status){
            case 0:
                this.message.error('¡Oh, no!','No se pudo establecer comunicación con el servidor, revise su conexión a internet');
                break;

            case 400:
            case 500:
                switch(error.json().status){
                    case 4004:
                        this.message.custom({
                            title: 'Renovar sesión',
                            text:error.json().message,
                            imageUrl: 'assets/img/padlock.png',
                            imageWidth: 100,
                            input: 'password',
                            inputPlaceholder: 'Introduzca su contraseña',
                            confirmButtonText: 'Renovar',
                            confirmButtonColor: '#04B404',
                            showCancelButton: false,
                            cancelButtonText: '¡Lo haré despues!',
                            reverseButtons:true,
                            cancelButtonColor:'#d33',
                            showLoaderOnConfirm: true,
                            inputAttributes: {
                                maxlength: '30',
                                autocapitalize: 'off',
                                autocorrect: 'off'
                            },
                            preConfirm: (password) => {
                                return new Promise((resolve) => {
                                    if (password === '') {
                                        this.message.validate("¡Ups!, aun no has introducido tu contraseña");
                                    }
                                    resolve();
                                })
                            }
                        }).then((result)=>{
                            if(result.value){
                                this.getOptions('login').then(options => {
                                    this.http.post(this.api+'/login/renew/',{ password : result.value }, options)
                                    .timeout(30000)
                                    .map((res:Response)=>{return res})
                                    .subscribe((data)  => {
                                        this.updateSession(data);
                                        this.message.success("¡Excelente!","Tu sesión se ha renovado exitosamente");
                                        //this.route.navigateByUrl(this.stage.getOpened());
                                    },(err:Response) => {
                                        this.message.error('¡Ups!',err.json().message).then(()=>{
                                            this.validateRequest(error);
                                        })
                                    });
                                })
                            }
                        });
                    break;

                    case 4002:
                        this.message.warning('¡Ups!',error.json().message+', por favor autentíquese nuevamente').then(()=>{
                            this.auth.logOut();
                        });
                    break;

                    default:
                        this.message.error('¡Ups!',error.json().message);
                    break;
                }
                break;

            case undefined:
                if(error.name){
                    switch(error.name){
                        case 'TimeoutError':
                            this.message.error('¡Ups!','El servidor ha excedido el tiempo de respuesta');
                        break;

                        default:
                            this.message.error('¡Oh, no!','Ha ocurrido algo inesperado, código : (RequestService-validateRequest-ErrorName) : ' + error.name);
                        break;
                    }
                }else{
                    this.message.error('¡Oh, no!','Ha ocurrido algo inesperado, código : (RequestService-validateRequest) : ' + JSON.stringify(error));
                    //cuando algo llegue aquí seria buena idea guardar para ir monitoreando posibles errores
                    //inesperados y agregarlos a las validaciones de error
                }
                break;

            default:
                switch (error.name) {
                    case 'TimeoutError':
                        this.message.error('¡Ups!', 'Se ha excedido el tiempo de respuesta del servidor');
                        break;

                    default:
                        this.message.error('¡Oh, no!', 'Ha ocurrido algo inesperado, código de error : ' + JSON.stringify(error.name));
                        break;
                }
                break;
        }
    }
}
