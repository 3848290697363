import { Injectable } from '@angular/core';
import { PushNotificationsService } from 'ng-push';

@Injectable()
export class PushNotificationService {
    constructor(
        private pushNotification:PushNotificationsService
    )
    {}

    public create(title,body,data):void{
        if(this.pushNotification.isSupported()){
            if(this.pushNotification.permission !== 'granted'){
                this.pushNotification.requestPermission();
            }else{
                if(this.pushNotification.isSupported() && this.pushNotification.permission === 'granted'){
                    this.pushNotification.create(title, {body: body,icon:'assets/img/logo_dm.png'})
                    .subscribe(
                        (res) => {
                            if (res.event.type === 'click') {
                                // console.log(data);
                                res.notification.close();
                            }
                        },
                        (err) => {
                            // console.log(err)
                        }
                    );
                }
            }
        }
    }

    public request():void{
        this.pushNotification.requestPermission();
    }

    public isGranted():boolean{
        if(this.pushNotification.permission === 'granted'){
            return true;
        }else{
            return false;
        }
    }

    public isSupported():boolean{
        return this.pushNotification.isSupported();
    }

    public permission():string{
        return this.pushNotification.permission;
    }

}
