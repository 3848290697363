export const environment = {
    production: false,
    app: "ExpressQuote",
    api: "https://api-expressquote.herokuapp.com/api/v1",
    newApi: "https://api-expressquote.onrender.com/api/v1",
    signLink: "https://expressquote-76960.firebaseapp.com/activate",
    socketIO: "https://api-expressquote.herokuapp.com/api/v1",
    settings: {},
    firebase: {
        projectName:"ExpressQuote",
        config : {
            apiKey: "AIzaSyBin35dktn9h-LASpGRdBe3uwhIfL9YDjA",
            authDomain: "expressquote-76960.firebaseapp.com",
            databaseURL: "https://expressquote-76960.firebaseio.com",
            projectId: "expressquote-76960",
            storageBucket: "expressquote-76960.appspot.com",
            messagingSenderId: "40511945495",
            appId: "1:40511945495:web:cb719018490e318fda9552"
        },
        functions:{
            users:"https://us-central1-expressquote-76960.cloudfunctions.net/users"
        }
    }
};
