import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
// import { StageService } from './stage.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Http, Response, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { NgRedux, select, Actions, IAppState } from './../modules/redux';
import { MessageService } from './message.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

    private errorCodes: any = {
        "auth/email-already-in-use": "La cuenta de correo proporcionada ya se encuentra en uso.",
        "auth/invalid-email": "Proporcione una cuenta de correo válida",
        "auth/user-not-found": "No existe una cuenta de usuario con el correo electrónico proporcionado",
        "auth/wrong-password": "La contraseña proporcionada es incorrecta",
        "auth/argument-error":"No se ha proporcionado una contraseña",
        "auth/invalid-action-code":"Acción denegada asegurese de estar utilizando un link de activación vigente, si el error persiste solicite de nuevo el correo de activación"
    }

    constructor(
        private db: AngularFirestore,
        private afAuth: AngularFireAuth,
        // private stage:StageService,
        private http: Http,
        private ngRedux: NgRedux<IAppState>,
        private message:MessageService,
        private router: Router
    ) {
        // db.firestore.settings({ timestampsInSnapshots: true });
    }

    public getToken(){
        return this.afAuth.auth.currentUser.getIdToken();
    }

    public getUserId(){
        return this.afAuth.auth.currentUser.uid;
    }

    public getUsername() {
        return this.afAuth.auth.currentUser.email;
    }

    public getEnterpriseId() {
        return this.afAuth.auth.currentUser.displayName;
    }

    public signIn(username,password):Promise<any> {
        return new Promise((resolve,reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(username, password).then((session)=>{
                this.ngRedux.dispatch({ type: Actions.CREATE_SESSION, payload:{user:{email:session.user.email,enterprise:session.user.displayName,uid:session.user.uid}} }); //Actualiza el estado de los permisos
                resolve(session);
            }).catch((error)=>{
                reject(error)
            })
        });
    }

    public recoverPassword(email):Promise<any>{
        // 7return new Promise((resolve,reject)=>{
            return this.afAuth.auth.sendPasswordResetEmail(email);
        // });
    }

    public signUp(username,password){
        return  this.afAuth.auth.createUserWithEmailAndPassword(username, password);
    }

    public sendSignInLink(email){
        var actionCodeSettings = {
            url: env.signLink,
            handleCodeInApp: true
        };
        return this.afAuth.auth.sendSignInLinkToEmail(email, actionCodeSettings);
    }

    public signInWithEmailLink(email,token) {
        return this.afAuth.auth.signInWithEmailLink(email,token);
    }

    public resendConfirmationEmail(){
        // return this.afAuth.auth.sendSignInLinkToEmail
        //return session.user.sendEmailVerification();
    }

    public sendConfirmationEmail(session) {
        return session.user.sendEmailVerification();
    }

    public logOut(){
        this.afAuth.auth.currentUser.getIdToken().then((token)=>{
            this.removeServerSession(token);
            // this.stage.stopListeners();
            this.afAuth.auth.signOut().then(()=>{
                this.router.navigateByUrl("/login");
                this.ngRedux.dispatch({ type: Actions.REMOVE_SESSION }); //Actualiza el estado de los permisos
            })
        });
    }

    public removeServerSession(token){
        let headers:any = {};
        headers['Content-Type'] = 'application/json';
        headers.token = token;
        headers.app = env.app;
        headers.module = 'logout';
        headers.uid = this.afAuth.auth.currentUser.uid;

        this.http.post(env.api+'/logout',null, new RequestOptions({
            headers: new Headers(headers),
        }))
        .timeout(10000)
        .map((res: Response) => { return res })
        .subscribe((data) => {

        }, (error: Response) => {

        });
    }

    public parseError(code) {
        return this.errorCodes[code];
    }

    public matchEnterpriseWithUser(data):Promise<any>{
        let Profile: any = {
            displayName: data.enterprise
        };
        return this.afAuth.auth.currentUser.updateProfile(Profile)
    }

    public onSignIn():Observable<any> {
        return new Observable(observer => {
            this.afAuth.authState.subscribe((sign) => {
                observer.next(sign);
            });
        });
    }

    public deleteFirebaseInstance() {
        localStorage.clear()
        this.afAuth.authState.subscribe((sign) => {
            this.router.navigateByUrl("login");
        });
    }

    public isSignedIn() {
        let isEmpty = (obj) => {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)){
                    return false;
                }
            }
            return true;
        }
        if (isEmpty(this.ngRedux.getState().session)){
            return false
        }
        return true;
    }

    public getEnterprise() { //?
        return this.afAuth.auth.currentUser.displayName;
    }

    getEnterpriseProfile(): Observable<any> {
        return this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .valueChanges();
    }

    getUserProfile(): Observable<any> {
        return this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('users')
            .doc(this.afAuth.auth.currentUser.uid)
            .valueChanges();
    }

    getUser(email): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(env.firebase.functions.users + "?email=" + email, new RequestOptions({
                headers: new Headers({ Authorization: "Bearer d90as8n7df89as7d0f8a7s0fdsa8fd7as098dnf7a08sd7" })
            }))
            .timeout(5000)
            .map((res: Response) => { return res })
            .subscribe((data) => {
                resolve(data.json());
            }, (error: Response) => {
                reject(error);
            });
        });
    }

    createUser(user): Promise<any> {
        let data:any = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            enterpriseId: user.enterpriseId,
            gender:user.gender,
            isActive:user.isActive
        };

        if(user.modules) {
            data.modules = user.modules;
        }

        if(user.permissions) {
            data.permissions = user.permissions;
        }

        return new Promise((resolve, reject) => {
            this.getToken().then((token) => {
                let headers:any = {};
                headers['Content-Type'] = 'application/json';
                headers.token = token;
                headers.app = env.app;
                headers.module = 'users';
                headers.uid = this.afAuth.auth.currentUser.uid;

                this.http.post(env.api+'/users',data, new RequestOptions({
                    headers: new Headers(headers),
                }))
                .timeout(10000)
                .map((res: Response) => { return res })
                .subscribe((data) => {
                    resolve(data.json());
                }, (error: Response) => {
                    reject(error);
                });
            });
        });
    }

    updateUser(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.put(env.firebase.functions.users, data, new RequestOptions({
                headers: new Headers({ Authorization: "Bearer d90as8n7df89as7d0f8a7s0fdsa8fd7as098dnf7a08sd7" })
            }))
            .timeout(5000)
            .map((res: Response) => { return res })
            .subscribe((data) => {
                resolve(data.json());
            }, (error: Response) => {
                reject(error);
            });
        });
    }

    getUserModules(): Promise<any> {
        return new Promise((resolve,reject) => {
            let ref = this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('users')
            .doc(this.afAuth.auth.currentUser.uid)
            .collection('modules')
            .snapshotChanges()
            .subscribe((snapshot) => {
                let Modules = [];
                snapshot.forEach((snap) => {
                    let data = snap.payload.doc.data();
                    let id = snap.payload.doc.id;
                    if (data.expire){
                        if (data.expireAt){
                            if (data.expireAt.seconds > moment().unix()) {
                                Modules[id] = data;
                            }
                        }
                    }else{
                        Modules[id] = data;
                    }
                });
                ref.unsubscribe();
                resolve(Modules);
            },(error)=>{
                ref.unsubscribe();
                reject(error);
            });
        });
    }

    validateModules(userModules): Promise<any> {
        return new Promise((resolve, reject) => {
            let ref = this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('modules')
            .snapshotChanges().subscribe((snapshot) => {
                let Modules = [];
                snapshot.forEach((snap) => {
                    let data = snap.payload.doc.data();
                    let id = snap.payload.doc.id;
                    if (userModules[id]) {
                        if (data.isActive) {
                            Modules[id] = Object.assign(data, userModules[id]);
                        }
                    }
                });
                ref.unsubscribe();
                resolve(Modules);
            }, (error) => {
                ref.unsubscribe();
                reject(error);
            });
        });
    }

    getUserPermissions(): Observable<any> {
        return new Observable((observer) => {
            this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('users')
            .doc(this.afAuth.auth.currentUser.uid)
            .collection('permissions')
            .snapshotChanges().subscribe((snapshot)=>{
                let Permissions = [];
                snapshot.forEach((snap) => {
                    let data = snap.payload.doc.data();
                    let id = snap.payload.doc.id;
                    if (data.expire) {
                        if (data.expireAt.seconds > moment().unix()) {
                            Permissions[id] = data;
                        }
                    } else {
                        Permissions[id] = data;
                    }
                });
                this.validatePermissions(Permissions).then((_permissions)=>{
                    observer.next(_permissions);
                }).catch((error)=>{
                    observer.error(error);
                });
            });
        });
    }

    validatePermissions(userPermissions):Promise<any>{
        return new Promise((resolve,reject)=>{
            let ref = this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('permissions')
            .snapshotChanges().subscribe((snapshot) => {
                let Permissions = [];
                snapshot.forEach((snap) => {
                    let data = snap.payload.doc.data();
                    let id = snap.payload.doc.id;
                    if (userPermissions[id]){ //No considera permisos que no existan para la empresa
                        if(data.active){ // verifica si la empresa tiene activo el permiso
                            Permissions[id] = Object.assign(data,userPermissions[id]);
                        }
                    }
                });
                ref.unsubscribe();
                resolve(Permissions);
            },(error) => {
                ref.unsubscribe();
                reject(error);
            });
        });
    }

    getGroups(userModules):Promise<any> {
        return new Promise((resolve,reject) => {
            this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('modulegroups')
            .snapshotChanges().subscribe((snapshot) => {
                let Groups = [];
                snapshot.forEach((snap)=>{
                    let data = snap.payload.doc.data();
                    let id = snap.payload.doc.id;
                    for(let moduleId in userModules){
                        if(userModules[moduleId].moduleGroupId == id){
                            Groups[id] = data;
                            break;
                        }
                    }
                });
                resolve(Groups);
            },(error)=>{
                reject(error);
            })
        });
    }

    public buildMenu():Promise<any>{
        return new Promise((resolve,reject) => {
            try {
                let Menu = [];
                let validModules:any;
                this.getUserModules().then((userModules)=>{

                    userModules['material_list'] = {expire:false,id:"material_list"}
                    userModules['technical_sheet'] = {expire:false,id:"technical_sheet"}
                    userModules['technical_sheets_gral'] = {expire:false,id:"technical_sheets_gral"}
                    return userModules; })
                .then((userModules) => {
                    this.validateModules(userModules).then((_validModules)=>{
                        validModules = _validModules;
                    }).then(() => {
                        this.ngRedux.dispatch({ type: Actions.SET_MODULES, payload: validModules });
                        this.getGroups(validModules).then((Groups) => {
                            return Groups;
                        }).then((Groups) => {
                            for (let groupId in Groups) {
                                let group: any = {
                                    description: Groups[groupId].description,
                                    icon: Groups[groupId].icon,
                                    tag: Groups[groupId].tag,
                                    modules: []
                                };
                                for (let moduleId in validModules) {
                                    if (validModules[moduleId].moduleGroupId == groupId) {
                                        group.modules.push(validModules[moduleId]);
                                    }
                                }
                                Menu.push(group);
                            }
                            this.ngRedux.dispatch({ type: Actions.SET_MENU, payload: Menu });
                            resolve(Menu);
                        })
                    })
                })
            } catch (error) {
                reject(error);
                throw error;
            }
        })
    }

    public runListeners():Observable<any>{
        return new Observable((observer)=>{
            // Escucha cambios en los permisos del usuario
            this.getUserPermissions().subscribe((permissions) => {
                this.ngRedux.dispatch({ type: Actions.SET_PERMISSIONS, payload: permissions }); //Actualiza el estado de los permisos
            });

            // Escucha cambios en los permisos de la empresa
            this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('permissions')
            .snapshotChanges().subscribe((snapshot) => {
                let ref = this.getUserPermissions().subscribe((permissions) => {
                    this.ngRedux.dispatch({ type: Actions.SET_PERMISSIONS, payload: permissions }); //Actualiza el estado de los permisos
                    ref.unsubscribe();
                });
            });


            // Escucha cambios en los modulos de la empresa
            this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('modules')
            .snapshotChanges().subscribe((snapshot) => {
                this.buildMenu().then(() => observer.next());
            });

            //Escucha cambios en las configuraciones
            this.db.collection('settings')
            .doc('web')
            .snapshotChanges().subscribe((snapshot)=>{
                let data = snapshot.payload.data();
                delete data['id'];
                env['settings'] = data;
            });

            // Escucha cambios en los grupos de la empresa
            this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('groups')
            .snapshotChanges().subscribe((snapshot) => {
                this.buildMenu().then(() => observer.next());
            });

            // Escucha cambios en modulos del usuario
            this.db.collection('enterprises')
            .doc(this.afAuth.auth.currentUser.displayName)
            .collection('users')
            .doc(this.afAuth.auth.currentUser.uid)
            .collection('modules')
            .snapshotChanges()
            .subscribe((snapshot) => {
                this.buildMenu().then(() => observer.next());
            });
        })
    }

    public hasModule(id){
        let session = this.ngRedux.getState().session;
        let module:any;
        try{ // valida si existe el módulo en el arreglo de módulos del usuario
            let module = session.modules[id];
            if (module.expire) {
                if (module.expireAt.seconds > moment().unix()) {
                    return true;
                } else {
                    this.message.warning("¡Se agotó el tiempo!", "El privilegio " + id + " han expirado con fecha de " + moment.unix(module.expireAt.seconds).format("MM/DD/YYYY, h:mm:ss a"));
                    return false;
                }
            } else {
                return true;
            }
        }catch(error){
            return false;
        }
    }

    public hasPermission(id){
        let session = this.ngRedux.getState().session;
        try{ // valida si existe el permiso en el arreglo de permisos del usuario
            let permission = session.permissions[id];
            if(permission){
                if (permission.expire) {
                    if (permission.expireAt.seconds > moment().unix()) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }else{
                return false;
            }
        }catch(error){
            return false;
        }
    }
}
