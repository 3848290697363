import { Injectable } from '@angular/core';
import { ToasterService } from './../../shared/services/toaster.service';
import { PushNotificationService } from './../../shared/services/push-notification.service';
import { SoundService } from './sound.service';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../modules/redux/store';
import { Actions } from '../modules/redux/actions';
@Injectable()
export class NotifyService {
    private _session:any;
    private EventConfigs:any = {};

    constructor(
        private push:PushNotificationService,
        private toast: ToasterService,
        private sound:SoundService,
        private ngRedux: NgRedux<IAppState>
    ){
        let { session } = this.ngRedux.getState();
        this._session = session;
    }

    create(data){
        this.EventConfigs = this._session.settings.notifications;
        if (this.EventConfigs){
            if (this.EventConfigs[data.module]){
                let types = this.EventConfigs[data.module];
                if (types[data.event]) {
                    let event = types[data.event];

                    let notify = {
                        type: event.type,
                        title: event.title,
                        content: data.user + event.content + data.payload.id,
                        config: event.config,
                        data: data,
                        sound: event.sound,
                        timestamp: Date.now()
                    };

                    // si se envia content y title del api los sustituye por los valores configurados por default
                    if (data.message !== undefined) {
                        if (data.message.content !== undefined && data.message.content !== '') {
                            notify.content = data.message.content;
                        }
                        if (data.message.title !== undefined && data.message.title !== '') {
                            notify.title = data.message.title;
                        }
                    }

                    this.toast.create(notify.type, notify.title, notify.content, notify.config, notify.data);
                    this.push.create(notify.title, notify.content, notify.data);
                    this.sound.play(notify.sound);
                    this.ngRedux.dispatch({ type: Actions.NEW_NOTIFICATION, payload: { notification: notify } });
                }else{
                    console.warn("No se encontró el evento " + data.event + " dentro de la configuración de alertas en tiempo real para el módulo " + data.module);
                }
            }else{
                console.warn("No se encontró el módulo " + data.module + " en la configuración de alertas en tiempo real.")
            }
        }{
            console.warn("No se encontró la configuración de alertas en tiempo real");
        }
    }
}
