import { ActivatedRoute } from '@angular/router';
import { tassign } from 'tassign';
import * as _ from "lodash";

export class StageReducer {
    private state;
    private action;

    constructor(
        state,
        action
    ) {
        this.state = state;
        this.action = action;
    }

    public addStage() {        
        let session = this.state.session;
        let stage = this.action.payload;

        let idxExstStage = _.findIndex(session.stages, { module: stage.module });

        if (idxExstStage === -1) {
            if (session.stages == undefined) {
                session.stages = [];
            }
            session.stages[stage.module] = stage;
        }
        return tassign(this.state, { session: session });
    }

    public removeStage() {
        let session = this.state.session;
        let module = this.action.payload.module;

        if (session.stages != undefined) {            
            delete session.stages[module];
        }
        
        return tassign(this.state, { session: session });
    }

    public setSchema(){
        let session = this.state.session;
        let schema = this.action.payload.schema;
        let module = this.action.payload.module;

        if(session.stages[module])
            session.stages[module].schema = schema;
        
        return tassign(this.state, { session: session });
    }

    public clearStages(){
        let session = this.state.session;

        if (session.stages != undefined) {
            delete session.stages;
        }
        
        return tassign(this.state, { session: session });
    }
}
