import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material';
import { MatStepperModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material';
import { MatTableModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatListModule } from '@angular/material';
import { MatMenuModule } from '@angular/material';
import { MatCardModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatExpansionModule,
        MatTableModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatCardModule,
        MatSelectModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatChipsModule,
        MatTooltipModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatDialogModule,
        MatSortModule,
        MatProgressBarModule,
        MatGridListModule,
        HttpClientModule
    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatStepperModule,
        MatExpansionModule,
        MatTableModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatCardModule,
        MatSelectModule,
        MatToolbarModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatChipsModule,
        MatTooltipModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatDialogModule,
        MatSortModule,
        MatProgressBarModule,
        MatGridListModule
    ],
    providers: [
        HttpClientModule
    ]
})
export class MaterialModule { }
