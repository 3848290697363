import { Component } from '@angular/core';
import { FirebaseApp } from '@angular/fire';

@Component({
    selector: 'body',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(public app: FirebaseApp){

    }
}
