export class User {
    public username:string;
    public name:string;
    public lastname:number;
    public email:string;
    public date:string;
    public password:string;
    public menu;
    public groups;
    public modules;
    public permissions;

    constructor(){
    }
}
