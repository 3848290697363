import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html'
})
export class AppSidebarComponent {
  @Output() toggleMinSidebar:EventEmitter<any> = new EventEmitter();

  minimize(event) {
    this.toggleMinSidebar.emit(event);
  }
 }
