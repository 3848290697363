import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionManager }  from './../../shared/services/session_manager';
import { Session } from '../../shared/models/session';
import { Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { SocketIOService } from './socket-io.service';

@Injectable()
export class RealTimeService {
    constructor(
        private SocketIOService:SocketIOService
    )
    {}

    public connect():Promise<any>{
        return new Promise((resolve, reject) => {
            this.SocketIOService.connect().then((connect) => {
                resolve(connect);
            })
        });
    }

    public auth():Promise<any>{
        return new Promise((resolve,reject)=>{
            this.SocketIOService.emitAuth().then((auth) => {
                if(auth.type == "error"){
                    switch (auth.moduleio) {
                        case 'ValidSessionToken':
                            this.SocketIOService.disconnect();
                            reject(auth.message);
                            break;
                        default:
                            reject(auth.message);
                            break;
                    }
                }
                resolve(auth);
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    public sendNotification(data):Promise<any>{
        return this.SocketIOService.emitEvent(data,'svrNotifications');
    }

    public onCruxEvent():Observable<any>{
        let observable = new Observable(observer => {
            this.SocketIOService.onEvent('cliCruxEvents').subscribe( data => {
                observer.next(data);
            });
        })
        return observable;
    }

    public onSelectedEvent(module,event):Observable<any>{
        let observable = new Observable(observer => {
            this.SocketIOService.onEvent('cliCruxEvents').subscribe( data => {
                if(data['module'] == module && data['event'] == event){
                    observer.next(data);
                }
            });
        })
        return observable;
    }

    public onNotificationEvent():Observable<any>{
        let count = 0;
        let observable = new Observable(observer => {
            this.SocketIOService.onEvent('cliNotifications').subscribe( data => {
                observer.next(data);
            });
        })
        return observable;
    }
}
