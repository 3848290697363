import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../shared/models/user';
import { TdLoadingService } from '@covalent/core/loading';
import { NgRedux, select, Actions, IAppState } from './../../shared/modules/redux';
import { AuthService } from './../../shared/services/auth.service';

@Component({
    templateUrl: 'activate.component.html',
    styleUrls:['./../login/login.component.scss'],
    providers:[User]
})
export class ActivateComponent {
    public loading = false;
    public validateEmail:boolean = false;
    public validatePassword:boolean = false;
    public validateConfirmPassword:boolean = false;
    private confirmPassword:string;
    private alerts:any[] = [];
    private url:string;

    constructor(
        private afAuth: AngularFireAuth,
        private user: User,
        private router: Router,
        private loader: TdLoadingService,
        private ngRedux : NgRedux<IAppState>,
        private auth:AuthService
    ) {}

    ngOnInit() {
        this.url = this.router.url;
        if (!this.afAuth.auth.isSignInWithEmailLink(this.url)) {
            this.router.navigateByUrl('/login');
        }
        if(this.auth.isSignedIn()){
            this.router.navigateByUrl('/welcome');
        }
    }

    activateAccount() {
        if(!this.invalidForm()){
            this.loader.register('loading');
            try {
                if (this.afAuth.auth.isSignInWithEmailLink(this.url)) {
                    this.afAuth.auth.signInWithEmailLink(this.user.email, this.url).then((sign) => {
                        this.afAuth.auth.currentUser.updatePassword(this.user.password).then((data) => {
                            this.loader.resolve('loading');
                            this.ngRedux.dispatch({ type: Actions.CREATE_SESSION, payload:{user:{email:this.user.email,enterprise:sign.user.displayName,uid:sign.user.uid}} }); //Actualiza el estado de los permisos
                            this.router.navigateByUrl('/welcome');
                        }).catch((error) => {
                            this.loader.resolve('loading');
                            this.router.navigateByUrl('/login');
                        })
                    }).catch((error)=>{
                        this.loader.resolve('loading');
                        if(error.code == 'auth/invalid-email')
                            this.showAlert("¡Oh!","El correo electrónico proporcionado no coincide con la cuenta de usuario que estás intentando activar","danger",10000);    
                        else
                            this.showAlert("¡Oh no!",this.auth.parseError(error.code),"danger",10000);
                    })
                }else{
                    this.loader.resolve('loading');
                }
            } catch (error) {
                this.loader.resolve('loading');
            }
        }
    }

    private showAlert(title, message,type, timeout): void {
        let alert = {
            timeout: timeout,
            title: title,
            message: message,
            type:type
        };
        this.alerts.push(alert);
    }

    private startValidate(input){
        switch(input){
            case 'email':
                this.validateEmail = true;
            break;

            case 'password':
                this.validatePassword = true;
            break;

            case 'confirmPassword':
            this.validateConfirmPassword = true;
        break;
        }
    }

    private messageError(input){
        let message = {
            required: "Este campo es obligatorio",
            minLenght: "Debe contener mínimo @ caracteres",
            maxLenght: "Debe contener máximo @ caracteres",
            invalidEmail: "La cuenta de correo proporcionada es inválida",
            confirmPassword: "Por favor confirma la contraseña",
            confirmError: "La contraseñas no coinciden"
        };
        
        switch (input) {
            case 'email':   
                if(this.validateEmail){
                    if(!this.user.email){
                        return message.required;
                    }
    
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!re.test(String(this.user.email).toLowerCase())){
                        return message.invalidEmail;
                    }
    
                    if (this.user.email.length > 50) {
                        return message.maxLenght.replace("@","50");
                    }  
                }
                break;

            case 'password':
                if(this.validatePassword){
                    if (!this.user.password){
                        return message.required;
                    }
    
                    if (this.user.password.length < 6){
                        return message.minLenght.replace("@","6");
                    }
    
                    if (this.user.password.length > 20) {                    
                        return message.maxLenght.replace("@","20");
                    } 
                }
                break;      
                
            case 'confirmPassword':
                if(this.validateConfirmPassword){
                    if (!this.confirmPassword){
                        return message.confirmPassword;
                    }

                    if(this.confirmPassword != this.user.password){
                        return message.confirmError;
                    }
                }
                break;                      
        }        

        return '';
    }

    private invalidForm(){
        if (!this.user.email || !this.user.password) return true;

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.user.email).toLowerCase())) return true;

        if (this.user.email.length > 50) return true;

        if (this.user.password.length > 20) return true;

        if (this.user.password.length < 6) return true;

        if (this.user.password != this.confirmPassword) return true;

        return false;
    }    
}