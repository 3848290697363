import { Injectable } from '@angular/core';

@Injectable()
export class SoundService {
    constructor() {}
    public play(sound){
        if(sound !== null &&  sound !== '' && sound !== undefined){
            let audio = new Audio();
            audio.src = "assets/sound/"+sound+".mp3";
            audio.load();
            audio.play();
        }
    }
}
