import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from './../../../shared/services/auth.service';
import { TranslateService } from './../../../shared/pipes/translate';
import { RequestService } from './../../../shared/services/request.service';
import { NgRedux, Actions, IAppState } from './../../../shared/modules/redux';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit{
    public session:any = {
        name:"",
        lastName:"",
        email:""
    };
    public flag:any = "";
    public translatedText: string;
    public supportedLangs: any[];
    @Output() onToggleTabs : EventEmitter<any> = new EventEmitter();
    @Output() onToggleSidebar : EventEmitter<any> = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private auth :  AuthService,
        private request:RequestService,
        private ngRedux: NgRedux<IAppState>
    ){

    }

    ngOnInit(): void {
        this.auth.onSignIn().subscribe((session) => {
            if(session){
                this.auth.getUserProfile().subscribe((profile) => {
                    this.session = profile;
                });
            }            
        });

        this.supportedLangs = [
            { display: 'English', value: 'en' },
            { display: 'Español', value: 'es' }
        ];
        this.selectLang('es');
    }

    LogOut(){
        this.auth.logOut();
    }

  isCurrentLang(lang: string) {
    // check if the selected lang is current lang
    return lang === this.translate.currentLang;
  }

  selectLang(lang: string) {
      // set current lang;
      if(lang === 'es'){
          this.flag = "flag flag-mx";
      }else{
          this.flag = "flag flag-us";
      }
      this.translate.use(lang);
      //this.refreshText();
  }

  refreshText() {
      // refresh translation when language change
      this.translatedText = this.translate.instant('hello world');
  }

    private toggleTabs(){
        this.onToggleTabs.emit()
    }

    private toggleSidebar(){
        this.onToggleSidebar.emit()
    }

}
