import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// componentes
import { Technical_sheetsComponent } from './components/technical_sheets.component';
// routing
import { Technical_sheetRoutingModule } from './technical_sheet-routing.module';


@NgModule({
    declarations: [
        Technical_sheetsComponent
    ],
    imports: [
        CommonModule,
        Technical_sheetRoutingModule
    ],
    exports:[
        Technical_sheetsComponent
    ]
})
export class Technical_sheetModule { }
