import { Component } from '@angular/core';

@Component({
  templateUrl: 'signup.component.html'
})
export class SignupComponent {

  constructor() { }

}
