import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../shared/models/user';
import { AuthService } from '../../shared/services/auth.service';
import { TdLoadingService } from '@covalent/core/loading';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
    providers: [
        User,
        TdLoadingService
    ]
})
export class LoginComponent {
    values = '';
    public loading = false;
    public external = true;
    public module:string = '';
    public state:string = '';
    public mode:string = 'login';
    public action:string = '';
    public data:string = '';
    public params:string = '';
    public validateEmail:boolean = false;
    public validatePassword:boolean = false;
    private rememberAcount:boolean;
    private alerts:any[] = [];
    private showPassword = false;

    constructor(
        private user: User,
        private auth: AuthService,
        private route: Router,
        private router: ActivatedRoute,
        private loader: TdLoadingService
    ) {}

    ngOnInit() {
        if(localStorage.getItem("email")){
            this.user.email = localStorage.getItem("email");
            this.rememberAcount = true;
        }
    }

    private showAlert(title, message,type, timeout): void {
        let alert = {
            timeout: timeout,
            title: title,
            message: message,
            type:type
        };
        this.alerts.push(alert);
    }

    logIn(user) {
        if(!this.invalidForm()){
            this.loader.register('loading');
            this.auth.signIn(user.email, user.password).then((session: any) => {
                this.loader.resolve('loading');
                if (session.user.emailVerified) {
                    if(this.rememberAcount){
                        localStorage.setItem("email",this.user.email);
                    }
                    this.route.navigateByUrl('welcome');
                } else {
                    this.showAlert("¡Ups!","Tu cuenta de correo aun no ha sido verificada por favor revisa tu bandeja de correo","danger",10000);
                }
            }).catch((error) => {
                this.loader.resolve('loading');
                this.showAlert("¡Ups!",this.auth.parseError(error.code),"danger",10000);
            });
        }
    }

    private forgotPassword() {
        this.mode = 'forgot';
    }

    private backToLogin(){        
        this.mode = 'login';
    }

    private recoverPassword(){
        this.auth.recoverPassword(this.user.email).then((data)=>{
            this.mode = 'login';
            this.showAlert("¡Éxito!","Te hemos enviado un correo de recuperación de contraseña","success",10000);
        }).catch((error)=>{
            this.showAlert("¡Ups!",this.auth.parseError(error.code),"danger",10000);
        })
    }

    private stopRemember(event){
        if(!event.checked){        
            localStorage.removeItem("email");
        }
    }

    private startValidate(input){
        switch(input){
            case 'email':
                this.validateEmail = true;
            break;

            case 'password':
                this.validatePassword = true;
            break;
        }
    }

    private messageError(input){
        let message = {
            required: "Este campo es obligatorio",
            minLenght: "Debe contener mínimo @ caracteres",
            maxLenght: "Debe contener máximo @ caracteres",
            invalidEmail: "Correo electrónico inválido",
        };
        
        switch (input) {
            case 'email':   
                if(this.validateEmail){
                    if(!this.user.email){
                        return message.required;
                    }
    
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!re.test(String(this.user.email).toLowerCase())){
                        return message.invalidEmail;
                    }
    
                    if (this.user.email.length > 50) {
                        return message.maxLenght.replace("@","50");
                    }  
                }
                break;

            case 'password':
                if(this.validatePassword){
                    if (!this.user.password){
                        return message.required;
                    }
    
                    if (this.user.password.length < 6){
                        return message.minLenght.replace("@","6");
                    }
    
                    if (this.user.password.length > 20) {                    
                        return message.maxLenght.replace("@","20");
                    } 
                }
                break;              
        }        

        return '';
    }

    private invalidForm(){
        if (!this.user.email) return true;

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.user.email).toLowerCase())) return true;

        if (this.user.email.length > 50) return true;

        if(this.mode == 'login'){
            if (!this.user.password) return true;            
            if (this.user.password.length > 20) return true;
            if (this.user.password.length < 6) return true;
        }        

        return false;
    }

    private toggleShowPassword(){
        this.showPassword = !this.showPassword;
    }
}
