import { tassign } from 'tassign';

export class CounterReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public increment() {
        return tassign(this.state, { newMessages: this.state.newMessages + 1 });
    }

    public decrement() {
        return tassign(this.state, { newMessages: this.state.newMessages - 1 });
    }
}
