export const LANG_EN_NAME = 'en';
export const LANG_EN_TRANS = {
    '#ENTERPRISES': 'Enterprises',
    '#PRODUCTS': 'Products',
    '#MATERIAL_LIST': 'Material List',
    '#CNC': 'CNC',
    '#CNC-ADMIN': 'CNC-ADMIN',
    '#TECHNICAL_SHEET':'technical_sheet',
    '#TECHNICAL_SHEETS_GRAL':'technical_sheets_gral',
    '#REPORTS': 'Reports',
    '#WORKORDER': 'Workorder',
    '#PROFILETYPES':'Profile types',
    '#PROFILES':'Profiles',
    '#QUOTATIONS':'quotations',
    '#ORDERS':'orders',
    '#QUOTATION' :'quotation',
    '#WAREHOUSES':'Warehouses',
    '#PRODUCTS-LINES-CAT':'products lines',
    '#MOVEMENTSTYPES': 'Movements Types',
    '#CUSTOMERS': 'Customers',
    '#MATERIALS':'Materials',
    '#PRODUCTION': 'Production',
    '#PRODUCT-LINE': 'product line',
    '#PRIVILEGES': 'privileges',
    '#USERSGROUP': 'users group',
    '#CUTTINGPROGRAMS': 'Cutting programs',
    '#CUTTINGMONITOR': 'Cutting monitor',
    '#CATALOGS': 'Catalogs',
    '#MACHINES': 'Machines',
    '#OPERATORS': 'Operators',
    '#DELIVERIES': 'Deliveries',
    '#DISCOUNTS': 'Discounts',
    '#REQUEST':'Request',
    '#MODULEGROUPS':'Module groups',
    '#MODULES':'Modules',
    '#ADMIN': 'Admin',
    '#COMMISSIONS':'Commissions',
    '#SALESAGENTS':'Sales agents',
    '#CALCULATE':'Calculate',
    '#RULES': 'Rules',
    '#QUOTATIONSREPORTS': 'Quotations reports',
    '#Programa de corte': 'Cutting program',
    '#Programas de corte': 'Cutting programs',
    '#Monitor de corte': 'Cutting monitor',
    '#Maquinas de corte': 'Cutting machines',
    '#Operadores de picking': 'Picking operators',
    '#Embarque': 'Deliveries',
    '#Transportista': 'Carrier',
    '#Descuentos': 'Discounts',
    '#Programa': 'Program',
    '#Programas': 'Programs',
    '#Pedido': 'Order',
    '#Pedidos': 'Orders',
    '#Folio': 'Folio',
    '#Folio del pedido': 'Order\'s folio',
    '#Capa': 'Layer',
    '#Cliente': 'Client',
    '#Clientes': 'Clients',
    '#Sucursal': 'Branch Office',
    '#Máquina': 'Machine',
    '#Operador': 'Picker',
    '#Partida': 'Item',
    '#Partidas': 'Items',
    '#Número de partidas': 'Number of cuttings',
    '#Corte': 'Cutting',
    '#Cortes': 'Cuttings',
    '#Partida pedido': 'Cutting order',
    '#Estatus': 'Status',
    '#Longitud': 'Length',
    '#Longitud Máxima': 'Max. Length',
    '#Clave Producto': 'Product key',
    '#Etiqueta capa': 'Layer label',
    '#Creado el': 'Created at',
    '#AREAS': 'Areas',
    '#Modulos': 'Modules',
    '#Creación': 'Created at',
    '#Producto': 'Product',
    '#Completa?': 'Complete?',
    '#Reportes': 'Reports',
    '#Informe de envíos': 'Shipment report',
    '#Etiquetas de pedidos': 'Order tags',
    '#Envíos': 'Deliveries',
    '#Unidad': 'Unit',
    '#Peso': 'Weight',
    '#Peso Total': 'Total weight',
    '#Descripción': 'Description',
    '#Cantidad': 'Quantity',
    '#Capa Comp.': 'Complete Layer',
    '#Imprimir packing list de embalaje':'Print package\'s packing list',
    '#Reenviar correo de seguimiento al cliente': 'Resend tracking mail to the client',
    // 'Número de corte': 'Cutting number',
    '#ID del programa': 'Program ID',
    //Units
    '#MT': 'm',
    //Cutting programs statuses
    '#Partial_transfered': 'Partial transfered',
    '#Delivery_Created':'Delivery created',
    //Cutting Programs Wizard Steps
    '#Seleccionar pedidos': 'Select orders',
    '#Confirmar programa': 'Confirm program',
    '#Asignar orden de capas': 'Layer sorting',
    //Order delivering types:
    '#Ocurre': 'Hold for pick up',
    '#Domicilio': 'Home delivery',
    //Status
    '#Pendientes': 'Pending',
    '#Procesando': 'Processing',
    '#Cortados': 'Cut',
    '#Empacado': 'Packaged',
    '#Envio creado': 'Delivery Created',
    '#Enviado': 'Sent',
    '#Todos': 'All',
    //Actions
    '#Nuevo': 'New',
    '#Nuevo Envío': 'New shipping',
    '#Refrescar': 'Refresh',
    '#Editar': 'Edit',
    '#Ayuda': 'Help',
    '#Guardar': 'Save',
    '#Deshacer': 'Undo',
    '#Salir': 'Leave',
    '#Expandir': 'Expand',
    '#Contraer': 'Collapse',
    '#Cola de trabajo': 'Work queue',
    '#Embalajes': 'Packages',
    '#Embalaje': 'Package',
    '#Número de envío': 'Delivery number',
    '#Destino del envío': 'Delivery destination',
    '#Imprimir formato de envío': 'Print delivery form',
    '#Enviado a embarque móvil': 'Sent to mobile package',
    '#Comprobante de envío': 'Delivery voucher',
    '#Transferir Envío': 'Transfer delivery',
    '#Tipo de envío': 'Delivery type',
    '#Hora de creación': 'Created at hour',
    '#Fecha de creación': 'Created at',
    '#Número de guía': 'Tracking code',
    '#¡Bienvenido! para continuar seleciona una sucursal': 'Welcome! to continue you must select a branch office',
    '#¡Excelente! solo un paso más, seleciona una máquina': 'Excellent! Just one more step, select a machine',
    '#Introduzca aquí lo que desea buscar': 'Search here',
    '#Introduzca aquí lo que desea filtrar': 'Filter here',
    '#Agrupar por': 'Group by',
    '#Ver': 'Watch',
    '#Ver monitor': 'Watch monitor',
    '#Ver buscador': 'Watch searcher',
    '#Ver detalles': 'See details',
    '#Detalles': 'Details',
    '#Ocultar buscador': 'Hide searcher',
    '#Consultar': 'Request',
    '#Seleccione una fecha inicial': 'Set a start date',
    '#Seleccione una fecha final': 'Set an end date',
    '#Identificador de capa': 'Layer identifier',
    '#Nivel de busqueda': 'Search level',
    '#Nivel de filtrado': 'Filter level',
    '#¡Rétame brody! escribe aquí la información que deseas filtrar en el nivel selecionado': 'Write here the information you want to filter in the selected level',
    '#Regresar a la cola de trabajo pendiente': 'Return to the pending job queue',
    '#Asignar todo a': 'Assign all layers to',
    '#Asignar todos los pedidos al programa': 'Assign all orders to the program',
    '#Editando el Programa de Corte': 'Editing the cutting program',
    '#Orden de búsqueda de las capas': 'Search order of the layers',
    '#Arrastre la capa y colóquela en el orden deseado.': 'Drag the layer and place it in the desired order.',
    '#Clave del producto de la capa' : 'Product key',
    '#Número de cortes a realizar': 'Number of cuts to be made',
    '#Área del almacén donde se ubica la capa': 'Warehouse area where the layer is located',
    '#Longitud total a cortar de la capa': 'Total length to be cut from the layer',
    '#Longitud mayor de corte': 'Cutting max. length',
    '#Pedido creado después de las 3:00 PM':'Order created after 3:00 PM',
    '#Intercambiar capa':'Exchange layer',
    '#Ver por': 'Watch by',
    '#Exportar datos de entrega': 'Export delivery data',
    //Notifications
    '#Debe asignar al menos una partida al programa': 'You must assign at least one cutting to the program',
    '#Todas las partidas deben tener pickers asignados': 'All layers must have pickers assigned',
    '#¡No puedes saltarte este paso!': 'Mandatory step',
    '#No cuenta con los permisos para realizar esta acción': 'You do not have the permissions to perform this action',
    '#Sin programas de corte.': 'No cutting programs',
    '#Sin embalajes': 'No packages',
    '#Sin envíos': 'No deliveries',
    '#No hay envíos listos en este momento': 'No deliveries ready in this moment',
    '#No hay embalajes en este momento': 'No packages in this moment',
    '#No se encontraron resultados': 'No results found',
    '#Sin cortes': 'No cuttings',
    '#Cortes asignados a Máquina': 'Cuttings assigned to machine',
    '#Seleccione a una sucursal para continuar.': 'You must assign a branch office to continue',
    '#Debe asignar una sucursal': 'You must assign a branch office',
    '#Debe asignar una máquina': 'You must assign a machine',
    '#No hay cortes asignados a esta máquina':'There are no cuttings assigned to this machine',
    '#Cortes pendientes con la etiqueta seleccionada': 'Pending cuttings with the selected label',
    '#Debe asignar una capa': 'You must assign a layer',
    '#No hay cortes pendientes para la capa seleccionada': 'There are no cuttings with the selected layer',
    '#La capa ingresada no existe o no se encuentra en el estatus correcto': "The selected layer doesn't exist or is not in the correct status",
    '#Sin trabajo pendiente': 'No pending work',
    '#No hay trabajo pendiente': 'There is no pending work',
    '#Cliente que solicitó el pedido': 'Customer who requested the order',
};
