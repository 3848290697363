import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule  } from '@covalent/core/steps';
import { CovalentDataTableModule } from '@covalent/core/data-table';
import { CovalentSearchModule } from '@covalent/core/search';
import { CovalentMessageModule } from '@covalent/core/message';
import { CovalentExpansionPanelModule } from '@covalent/core/expansion-panel';
import { CovalentLoadingModule } from '@covalent/core/loading';

@NgModule({
  imports: [
    CommonModule,
    CovalentLayoutModule,
    CovalentStepsModule,
    CovalentDataTableModule,
    CovalentSearchModule,
    CovalentMessageModule,
    CovalentExpansionPanelModule,
    CovalentLoadingModule
  ],
  exports: [
    CovalentLayoutModule,
    CovalentStepsModule,
    CovalentDataTableModule,
    CovalentSearchModule,
    CovalentMessageModule,
    CovalentExpansionPanelModule,
    CovalentLoadingModule
  ],
  declarations: []
})
export class CovalentModule { }
