import { tassign } from 'tassign';

export class SettingsReducer {
    private state;
    private action;

    constructor(state,action){
        this.state = state;
        this.action = action;
    }

    public setSettings() {
        let settings = this.action.payload.settings;
        let session = JSON.parse(localStorage.getItem("session"));
        session.settings = settings;
        localStorage.setItem("session", JSON.stringify(session));
        return tassign(this.state, { session: session });
    }
}
